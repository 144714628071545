import React from "react"
// import { graphql, useStaticQuery } from "gatsby"
// import Img from "gatsby-image"

import "../assets/components/bootstrap/css/bootstrap.min.css"

import "../assets/scss/theme.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Logo from "../../static/images/Leikur_logo.svg"

const App = () => {
  // const query = useStaticQuery(graphql`
  //   query {
  //     top: file(relativePath: { eq: "top_d.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1920) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }

  //     topMobile: file(relativePath: { eq: "top_m.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 550) {
  //           ...GatsbyImageSharpFluid_withWebp
  //         }
  //       }
  //     }

  //     cards: file(relativePath: { eq: "cards.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 960) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }

  //     students: file(relativePath: { eq: "Students.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 960) {
  //           ...GatsbyImageSharpFluid
  //         }
  //       }
  //     }
  //   }
  // `)

  return (
    <Layout>
      <SEO title="Home" />
      <header id="top">
        <div className="logo text-center">
          <Logo className="my-6 my-md-9" />
        </div>
        {/* <div className="position-absolute p-2 p-sm-4" style={{top: 0, right: 0}}>
          <a className="p-2" href="https://innostream-poc.appiancloud.com/suite?signin=leikur">Sign Up</a>
          <a className="p-2" href="https://innostream-poc.appiancloud.com/suite?signin=leikur">Login</a>
        </div> */}
      </header>

      <main className="main-container">
        <section id="services" className="section p-0">
          <div className="container">
            <div className="row mb-4">
              <div className="col-12">
                <header className="text-center">
                  <h3 className="mt-0 font-italic">
                    Leikur: (masc.), [ˈlaikʊr], from Old Norse Leikr.
                    Definition: 1. match, 2. move or 3. performance.
                  </h3>
                </header>
              </div>
            </div>
          </div>
        </section>
        <section id="images" className="section p-0">
          <div className="container-fluid p-0">
            <div className="row d-flex">
              <div className="col-6 col-md-3 px-0">
                <img
                  src="/images/gold.png"
                  alt=""
                  className="w-100"
                  style={{ maxHeight: "200px", objectFit: "cover" }}
                />
              </div>
              <div className="col-6 col-md-3 px-0">
                <img
                  src="/images/blue.png"
                  alt=""
                  className="w-100"
                  style={{ maxHeight: "200px", objectFit: "cover" }}
                />
              </div>
              <div className="col-6 col-md-3 px-0">
                <img
                  src="/images/pink.png"
                  alt=""
                  className="w-100"
                  style={{ maxHeight: "200px", objectFit: "cover" }}
                />
              </div>
              <div className="col-6 col-md-3 px-0">
                <img
                  src="/images/green.png"
                  alt=""
                  className="w-100"
                  style={{ maxHeight: "200px", objectFit: "cover" }}
                />
              </div>
            </div>
          </div>
        </section>
        <section id="details" className="section">
          <div className="container">
            <div className="row">
              <div className="col-12">
                Leikur Holding exists to facilitate investment into growing
                Fintech companies and start-ups. We provide vital business
                intelligence to a global market of investors searching for
                upcoming, exceptional investment opportunities.
                <br />
                <br />
                Leikur Holding’s founders have decades long experience as
                innovators in the financial industry, leading edge experience
                from the investing sectors, extensive networks, and
                all-embracing strategy & marketing know-how.
                <br />
                <br />
                Our services give you the competitive edge needed when making
                your next move. Welcome to Leikur.
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  )
}

export default App
