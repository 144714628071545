import React from "react"
// import LogoBottom from "../../static/images/Logo_bottom.svg"

const Footer = () => {
  return (
    <footer className="footer footer-light text-center d-md-flex flex-row justify-content-center mx-lg-12 mb-lg-10 mx-md-10 mb-md-8">
      {/* <LogoBottom
        style={{
          height: 45,
        }}
      /> */}
      <div className="text-muted align-self-center pt-2 py-md-4">
        Leikur Holding Ltd &copy; 2023 |{" "}
        <a href="mailto:connect@leikur.eu">connect@leikur.eu</a>
      </div>
    </footer>
  )
}

export default Footer
